var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import NewsSearchContext from "../../../application/Context/NewsSearchContext";
import { MonthInput } from "../shared-components/Monthinput/MonthInput";
import { YearInput } from "../shared-components/YearInput/YearInput";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import RemoteMultiSelect from "../shared-components/MultiSelect/RemoteMultiSelect";
export var Filters = function () {
    var _a;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var t = useTranslation(["news"]).t;
    var _b = React.useContext(NewsSearchContext), selectedCategory = _b.selectedCategory, handleCategoryChange = _b.handleCategoryChange, months = _b.months, handleMonthChange = _b.handleMonthChange, handleYear = _b.handleYear, yearInput = _b.yearInput, filtersIsLoading = _b.filtersIsLoading;
    return (_jsxs(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.2 }, exit: { opacity: 0 } }, { children: [_jsx(RemoteMultiSelect, { placeHolder: t("Select one or more categories"), title: "Catégories", handleClick: handleCategoryChange, SelectedChoices: selectedCategory, disabled: filtersIsLoading, relatedField: "categorie", ESindex: "New", column: "nom_".concat(langCode), operator: "icontains", valueColumn: "id", getCustomLabel: function (option) { return option["nom_".concat(langCode)]; } }), _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-md-8 p-0" }, { children: _jsx(MonthInput, { title: "Mois", type: "checkbox", months: months, onChange: handleMonthChange, disabled: filtersIsLoading }) })), _jsx("div", __assign({ className: "col-md-4 p-0 ps-md-1" }, { children: _jsx(YearInput, { type: "number", yearInput: yearInput, onChange: handleYear, disabled: filtersIsLoading }) }))] }))] })));
};
