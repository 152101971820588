var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
export var NotFound = function () {
    var _a;
    var t = useTranslation(['news']).t;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var urlNews = langCode == "fr" ? "/fr/nouvelles/" : "/en/news/";
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "not-found-container" }, { children: [_jsxs("div", __assign({ className: "content" }, { children: [_jsxs("div", __assign({ className: "title mt-2 me-0" }, { children: [" ", t("Aucun résultat de recherche"), " "] })), _jsx("p", __assign({ className: "mt-2" }, { children: t("Malheureusement il n'y a pas de résultat selon votre demande. Veuillez essayer un mot-clé.") }))] })), _jsxs("a", __assign({ className: "btn btn-primary mt-5 me-0", href: urlNews }, { children: [" ", t("Dernières nouvelles"), " "] }))] })) }));
};
